<template>
    <!-- Hero section -->
    <main class="hero">
      <!-- Input form -->
      <div class="form" v-if="!shortUrl">
        <form
        @submit.prevent="postShort"
          method="post"
          id="main-form"
        >
          <input
            type="url"
            name="original_url"
            autocomplete="off"
            placeholder="Enter your ulr..."
            v-model="longUrl"
            required
          />
        </form>
        <button type="submit" form="main-form" class="submit-button">
          Shorten
          <img class="link-logo" src="static/assets/link-icon.svg" />
        </button>
      </div>

    <!-- Shortened link -->
    <div class="result" v-show="shortUrl">
        <div class="display-link">
          <p id="to-cp">
            {{ shortUrl }}
            <!-- {{ url_for('short.redirect_to_url', short_url=new_link,
            _external=True) }} -->
          </p>
        </div>
        <div class="copy-button-container">
          <button class="copy-button" @click="copyText()">
            Copy
            <img class="copy-logo" src="static/assets/link-icon.svg" />
          </button>
        </div>
      </div>
      <p class="description"  v-show="shortUrl">
        Directly copy the URL above or scan the QR code below!
      </p>
      <!-- QR code -->
      <div class="img-container"  v-show="shortUrl">
        <img style="text-align: center" :src="qrCode" alt="QR-Code" >
      </div>
      
    

      <!-- About cards -->
      <section class="cards-container">
        <div class="card">
          <h3>Speed</h3>
          <p>We offer a high performance app, optimized for speed quality.</p>
        </div>
        <div class="card">
          <h3>Simplicity</h3>
          <p>
            Shorty is a user-friendly app, aimed to be easely usable by
            everyone.
          </p>
        </div>
        <div class="card">
          <h3>Quality</h3>
          <p>We offer a high quality product aimed to accustom your needs.</p>
        </div>
      </section>
    </main>  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data: function(){
    return {
      longUrl:'',
      shortUrl:'',
      qrCode:'',
      shorties:[]
    }
  },
  methods:{
    postShort: async function(){
      console.log(`new url to shorten: ${this.longUrl}`)
      let resp = await  fetch("/api/shorties",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({url:this.longUrl}),
      });

      this.shortUrl  = resp.headers.get('Location');
      this.qrCode = (await resp.json()).qrCode;

      // let surl = await resp.text();
      // this.shortUrl = surl;
      // let json  = await resp.json();

      // console.dir(json)
      
      // await this.getShorts();
      
    },
    copyText: function () {
    const text = document.getElementById("to-cp").innerText;
    // Creating an input field - this due to JS only being able to copy stuff from 'selected' elements,
    // the only elements where '.select()' can be called on are 'textarea' and 'input'
    const textArea = document.createElement("textarea");

    // Adding 'text' to it, adding it to the body and selecting it
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    // Hiding the textarea
    textArea.style.position = "absolute";
    textArea.style.right = "-9999";

    // Copying the text and removing the element
    document.execCommand("copy");
    document.body.removeChild(textArea);
}
  }
}
</script>
